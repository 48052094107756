import { AppProps } from "next/app";
import Head from "next/head";
import Router from "next/router";
import { Auth0Provider } from "@auth0/auth0-react";

import "../styles/globals.css";

const onRedirectCallback = (appState) => {
  Router.replace(appState?.returnTo || "/");
};

export default function PondAdminApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>Goodside Health | Admin</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={"/favicon.ico"} />
        <link rel="apple-touch-icon" href={"/favicon.ico"} />
      </Head>

      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_JWT_DOMAIN as string}
        clientId={process.env.NEXT_PUBLIC_JWT_CLIENT_ID as string}
        authorizationParams={{
          redirect_uri:
            typeof window !== "undefined" ? window.location.origin : "",
          audience: process.env.NEXT_PUBLIC_JWT_AUDIENCE as string,
        }}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <Component {...pageProps} />
      </Auth0Provider>
    </>
  );
}
